<template>
  <ais-instant-search :search-client="searchClient" :index-name="searchIndex">
    <ais-search-box
      :class-names="{
        'ais-SearchBox': 'searchbox',
      }"
    >
      <debounced-search-box
        :search-index-suggestions="searchIndex + '_query_suggestions'"
        :delay="250"
        :search-base-url="searchBaseUrl"
        :placeholder="placeholder"
        :event="event"
      />
    </ais-search-box>
  </ais-instant-search>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import DebouncedSearchBox from "./DebouncedSearchBox.vue";
import { AisInstantSearch, AisSearchBox } from "vue-instantsearch";

export default {
  components: {
    DebouncedSearchBox,
    AisInstantSearch,
    AisSearchBox,
  },
  props: {
    searchIndex: {
      type: String,
      required: true,
    },
    searchBaseUrl: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    event: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchClient: algoliasearch(
        document.querySelector(
          'meta[name="VUE_APP_ALGOLIASEARCH_APPLICATION_ID"]'
        ).content,
        document.querySelector('meta[name="VUE_APP_ALGOLIASEARCH_API_KEY"]')
          .content
      ),
    };
  },
};
</script>
