var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.optionList),function(option,index){return _c('div',{key:index,staticClass:"option",class:{
      'option--unavailable': !_vm.options[option.type].available,
      'option--active': option.active,
    },on:{"click":function($event){return _vm.setFilters(
        _vm.options[option.type].startS,
        _vm.options[option.type].endS,
        option.type
      )}}},[_c('div',{staticClass:"checkbox"}),_vm._v(" "+_vm._s(option.name)+" ")])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }