<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="search-item search-item--page">
    <div class="item__image">
      <img v-if="item.featuredImageUri" :src="item.featuredImageUri" />
    </div>

    <div class="item__content">
      <a :href="item.slug" class="item__title" v-html="name"></a>
      <p class="item__paragraph" v-html="intro"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchItemPagina",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    intro() {
      let intro = this.item._highlightResult.intro
        ? this.item._highlightResult.intro.value
        : this.item.intro;
      if (!intro) return null;

      if (intro.length > 140) {
        // Get only 140 first characters
        intro = intro.substring(0, 140);

        // Remove everything after last space (to prevent partial words)
        intro = intro.substr(0, intro.lastIndexOf(" "));

        // Add dots of it isn't the end of a line
        if (intro.charAt(-1) !== ".") {
          intro += "...";
        }
      }

      return intro;
    },
    name() {
      return this.item._highlightResult.name
        ? this.item._highlightResult.name.value
        : this.item.name;
    },
  },
};
</script>
