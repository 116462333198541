<template>
  <nav v-if="totalResult > 0" class="search-nav">
    <h2 class="title title-l">{{ queryLine }}</h2>
    <button class="search-nav__item" @click="$emit('switch', 'products')">
      {{ totals.products }} {{ "offers" | t }}
    </button>
    •
    <button class="search-nav__item" @click="$emit('switch', 'blogs')">
      {{ totals.blogs }} {{ "blogs" | t }}
    </button>
    •
    <button class="search-nav__item" @click="$emit('switch', 'pages')">
      {{ totals.pages }} {{ "pages" | t }}
    </button>
  </nav>
</template>

<script>
export default {
  name: "SearchNav",
  props: {
    totals: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalResult() {
      return Object.values(this.totals).reduce((a, b) => a + b);
    },
    queryLine() {
      const urlParams = new URLSearchParams(window.location.search);
      const query = urlParams.get("query"); // Get search query
      let resultText =
        this.totalResult + " " + this.$options.filters.t("results");
      if (query) {
        resultText += " " + this.$options.filters.t("for") + ' "' + query + '"';
      }
      return resultText;
    },
  },
};
</script>
