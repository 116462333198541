<template>
  <div class="search-page">
    <div class="grid-container">
      <SearchNav :totals="totals" @switch="switchPage" />
      <SearchResults
        :class="{ active: page === 'products' }"
        endpoint="products"
        type="product"
        name="offers"
        @total="totals.products = $event"
      />
      <SearchResults
        :class="{ active: page === 'blogs' }"
        endpoint="search/blogs"
        type="article"
        name="blogs"
        @total="totals.blogs = $event"
      />
      <SearchResults
        :class="{ active: page === 'pages' }"
        endpoint="search/pages"
        type="page"
        name="pages"
        @total="totals.pages = $event"
      />
    </div>
  </div>
</template>

<script>
import SearchNav from "./SearchNav.vue";
import SearchResults from "./SearchResults.vue";

export default {
  name: "SearchPage",
  components: {
    SearchNav,
    SearchResults,
  },
  data() {
    return {
      totals: {
        products: 0,
        blogs: 0,
        pages: 0,
      },
      page: "products",
    };
  },
  methods: {
    switchPage(page) {
      this.page = page;
    },
  },
};
</script>
