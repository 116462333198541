var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-instant-search',{attrs:{"search-client":_vm.client,"index-name":_vm.indexName,"routing":_vm.routing,"search-function":_vm.searchFunction,"initial-ui-state":_vm.initialUiState}},[(_vm.filtersFormatted)?_c('ais-configure',{attrs:{"filters":_vm.filtersFormatted,"page":0,"hitsPerPage":20,"maxValuesPerFacet":20,"attributes":['*']}}):_vm._e(),_c('div',{staticClass:"algolia-search grid-container",class:{ 'algolia-search--category': _vm.category }},[(_vm.showSearch)?_c('div',{staticClass:"algolia-search__search"},[_c('ais-search-box',{attrs:{"class-names":{
          'ais-SearchBox': 'searchbox',
        }}},[_c('debounced-search-box',{attrs:{"search-index-suggestions":_vm.indexName,"delay":500,"search-base-url":_vm.searchBaseUrl,"placeholder":_vm.labels.search}})],1)],1):_vm._e(),_c('div',{staticClass:"algolia-search__sidebar"},[_c('button',{staticClass:"button--secondary button--filters",on:{"click":_vm.switchFilters}},[_c('i',{staticClass:"fal fa-sliders-h"}),_vm._v("Filters ")]),_c('div',{staticClass:"filters-header"},[_c('ais-current-refinements',{attrs:{"transform-items":_vm.transformCurrentRefinements}})],1),_c('div',{staticClass:"filters-lists",class:{ 'filters-lists--open': _vm.filtersOpen }},[_c('div',{staticClass:"filters-lists__header"},[_c('h4',[_vm._v("Filters")]),_c('button',{staticClass:"button--close",on:{"click":_vm.switchFilters}},[_c('i',{staticClass:"fal fa-times"})])]),_c('button',{staticClass:"button--primary button--show-results",on:{"click":_vm.switchFilters}},[_c('ais-stats',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var nbHits = ref.nbHits;
return _c('p',{},[_vm._v(" Toon "+_vm._s(nbHits)+" "+_vm._s(nbHits > 1 ? _vm.labels.results : "resultaat")+" ")])}}])})],1),_vm._l((_vm.attributes),function(attribute,index){return _c('div',{key:index,staticClass:"filters-list",class:{ hide: attribute.type === 'range' }},[_c('h4',{staticClass:"filters-title"},[_vm._v(_vm._s(attribute.name))]),_c('ais-refinement-list',{attrs:{"attribute":attribute.metadata,"searchable":attribute.filter,"searchable-placeholder":("Zoeken in " + (attribute.name.toLowerCase()) + "..."),"limit":5,"show-more":""}})],1)}),(_vm.attributes && _vm.attributes.length && _vm.category && _vm.showDateFilter)?_c('div',{staticClass:"filters-list"},[_c('h4',{staticClass:"filters-title"},[_vm._v("Datum")]),_c('search-date-filter',{attrs:{"attribute":{}},on:{"filterDate":_vm.setDateFilters}})],1):_vm._e()],2)]),_c('div',{staticClass:"algolia-search__main"},[_c('ais-stats',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var nbHits = ref.nbHits;
        var query = ref.query;
return _c('p',{staticClass:"algolia-search__results",class:{ bold: !nbHits }},[(query)?_c('b',[_vm._v(" "+_vm._s(nbHits)+" "+_vm._s(_vm.labels.resultsFor)+" ‘"+_vm._s(query)+"’ ")]):_c('b',[_vm._v(" "+_vm._s(nbHits)+" "+_vm._s(_vm.labels.results)+" ")])])}}])}),_c('ais-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var items = ref.items;
return _c('search-list',{attrs:{"items":items,"labels":_vm.labels,"placeholder-image":_vm.placeholderImage,"category":_vm.category}},[_vm._v(" "+_vm._s(_vm.labels.noResultsSentence)+" ")])}}])}),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var nbPages = ref.results.nbPages;
return [(nbPages > 1)?_c('ais-pagination',{staticClass:"pagination",attrs:{"total-pages":5}}):_c('div')]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }