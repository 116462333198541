<template>
  <div>
    <div v-click-outside="handleClickOut" class="search-box__container">
      <input
        v-model="query"
        required
        type="search"
        :placeholder="placeholder"
        class="search-box__input search-box__input--has-close-icon"
        @focus="inputFocus = true"
        @keypress.enter="handleEnter"
      />
      <span class="search-box__reset-btn" @click="handleCloseClick"> </span>
    </div>
    <ais-index :index-name="searchIndexSuggestions">
      <ais-hits
        v-if="inputFocus"
        :transform-items="filterHits"
        class="search-autocomplete search-autocomplete--small"
      >
        <ul slot-scope="{ items }" class="search-autocomplete__list">
          <li
            v-for="item in items"
            :key="item.objectID"
            class="search-autocomplete__item"
          >
            <a
              class="search-autocomplete__link"
              href="#"
              @click.prevent="handleClick(item.query)"
            >
              <ais-highlight
                attribute="query"
                :hit="item"
                highlighted-tag-name="span"
                :class-names="{
                  'ais-Highlight': 'highlight',
                  'ais-Highlight-highlighted': 'highlight__thin',
                }"
              />
            </a>
          </li>
        </ul>
      </ais-hits>
    </ais-index>
  </div>
</template>

<script>
import searchHistory from "../../../../services/search/searchHistory";
import { connectSearchBox } from "instantsearch.js/es/connectors";
import {
  createWidgetMixin,
  AisHits,
  AisIndex,
  AisHighlight,
} from "vue-instantsearch";

import ClickOutside from "vue-click-outside";

import EventBus from "../../../../event-bus";

export default {
  components: {
    AisHits,
    AisIndex,
    AisHighlight,
  },
  directives: {
    ClickOutside,
  },
  mixins: [createWidgetMixin({ connector: connectSearchBox })],
  props: {
    delay: {
      type: Number,
      default: 200,
      required: false,
    },
    searchIndexSuggestions: {
      type: String,
      required: true,
    },
    searchBaseUrl: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    event: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      inputFocus: false,
      timerId: null,
      localQuery: "",
    };
  },
  computed: {
    query: {
      get() {
        return this.localQuery;
      },
      set(val) {
        this.localQuery = val;
        if (this.timerId) {
          clearTimeout(this.timerId);
        }
        this.timerId = setTimeout(() => {
          if (this.event) {
            EventBus.$emit(this.event, {
              event: "type",
              params: {
                query: this.localQuery,
              },
            });
          }

          this.addHistory(this.localQuery);
          this.state.refine(this.localQuery);
        }, this.delay);
      },
    },
  },

  destroyed() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  },
  created() {
    this.localQuery = new URLSearchParams(window.location.search).get("query");
  },
  methods: {
    handleEnter() {
      if (this.event) {
        EventBus.$emit(this.event, {
          event: "click",
          params: {
            query: this.query,
          },
        });
      }
      this.inputFocus = false;
    },
    handleCloseClick() {
      this.localQuery = "";
      this.state.refine("");
    },
    filterHits(items) {
      return items.filter((item) => {
        return item._highlightResult.query.matchLevel !== "none";
      });
    },
    handleClick(query) {
      if (this.event) {
        EventBus.$emit(this.event, {
          event: "click",
          params: {
            query,
          },
        });
      }
      this.state.refine(query);
      this.addHistory(query);
      this.localQuery = query;
      this.inputFocus = false;
    },
    handleClickOut() {
      this.inputFocus = false;
    },
    addHistory(query) {
      searchHistory.add({
        title: query,
        url: `${this.searchBaseUrl}?${new URLSearchParams({
          query,
        }).toString()}`,
        query,
      });
    },
  },
};
</script>
