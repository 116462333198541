<template>
  <div class="search-results">
    <p v-if="resultsAvailable" class="search-query title-m">
      {{ total }} {{ name | t }} {{ "found" | t }}
    </p>

    <product-list
      :products="items"
      :type="type"
      :class="'product-list--' + type"
    />

    <button
      v-if="loadMoreVisible"
      class="button--secondary load-more"
      :class="{ 'button--loading': loading }"
      @click="loadMore"
    >
      <i class="far fa-arrow-down"></i>{{ "Load more" | t }}
    </button>
  </div>
</template>

<script>
import api from "../../../services/api";

export default {
  name: "SearchResults",
  props: {
    endpoint: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      items: null,
      searchQuery: null,
      per_page: 36,
      loading: true,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    loadMoreVisible() {
      return this.items && this.items.length !== this.total;
    },
    resultsAvailable() {
      return this.items && this.items.length > 0;
    },
  },
  mounted() {
    this.getParameters();
  },
  methods: {
    getItems({ initial = false, loadMore = false } = {}) {
      let query =
        "/" +
        this.endpoint +
        "?per_page=" +
        this.per_page +
        "&page=" +
        this.currentPage;

      let parameters = "";

      if (this.searchQuery) {
        parameters += "&q=" + this.searchQuery;
      }

      //Don't run function on page load (it will remove the parameters)
      if (!initial) this.setParameters(parameters);

      //cleanup query
      query += parameters;
      query.replace("?&", "?");

      //Make the call
      this.loading = true;
      api
        .get(query)
        .then((response) => {
          // api.get defaults to falseOnFailure=true
          if (response === false) {
            return;
          }

          let items;
          if (this.endpoint === "products") {
            items = response.products.data;
            this.total = response.products.total;
          } else {
            items = response.data;
            this.total = response.meta.pagination.total;
          }
          if (loadMore) {
            // On loadMore, add new items to productlist
            if (this.endpoint === "products") {
              items = this.items.concat(response.products.data);
            } else {
              items = this.items.concat(response.data);
            }
          }
          this.items = items;
          this.$emit("total", this.total);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setParameters(parameters) {
      let url = window.location.href.split("?")[0];
      if (parameters) url += "?" + parameters.slice(1);
      window.history.replaceState(null, null, url);
    },
    getParameters() {
      const urlParams = new URLSearchParams(window.location.search);

      //Check if there has been a search
      const search = urlParams.get("q");
      if (search) this.searchQuery = search;

      //Run initial get items
      this.getItems({ initial: true });
    },
    loadMore() {
      if (!this.loadMoreVisible) return;
      this.currentPage++;
      this.getItems({ loadMore: true });
    },
  },
};
</script>
