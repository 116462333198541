<template>
  <div>
    <div
      v-for="(option, index) in optionList"
      :key="index"
      class="option"
      :class="{
        'option--unavailable': !options[option.type].available,
        'option--active': option.active,
      }"
      @click="
        setFilters(
          options[option.type].startS,
          options[option.type].endS,
          option.type
        )
      "
    >
      <div class="checkbox"></div>
      {{ option.name }}
    </div>
  </div>
</template>

<script>
import {
  ALGOLIA_SEARCH_EVENT_DATE_START,
  ALGOLIA_SEARCH_EVENT_DATE_END,
} from "../../../../services/search/constants";
// @TODO: Deze widget refactoren als custom-widget volgens de norm.
// https://www.algolia.com/doc/guides/building-search-ui/widgets/create-your-own-widgets/vue/

import {
  format,
  startOfToday,
  endOfToday,
  startOfTomorrow,
  endOfTomorrow,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addWeeks,
} from "date-fns";

export default {
  name: "SearchDateFilter",
  inject: {
    instantSearchInstance: {
      from: "$_ais_instantSearchInstance",
      default() {
        const tag = this.$options._componentTag;
        throw new TypeError(
          `It looks like you forgot to wrap your Algolia search component "<${tag}>" inside of an "<ais-instant-search>" component.`
        );
      },
    },
  },
  data() {
    return {
      optionList: [
        {
          name: "Vandaag",
          type: "today",
          active: false,
        },
        {
          name: "Morgen",
          type: "tomorrow",
          active: false,
        },
        {
          name: "Deze week",
          type: "thisWeek",
          active: false,
        },
        {
          name: "Volgende week",
          type: "nextWeek",
          active: false,
        },
        {
          name: "Deze maand",
          type: "month",
          active: false,
        },
      ],
    };
  },
  computed: {
    options() {
      const week = addWeeks(new Date(), 1);
      return {
        today: this.convertToDateProps(startOfToday(), endOfToday()),
        tomorrow: this.convertToDateProps(startOfTomorrow(), endOfTomorrow()),
        thisWeek: this.convertToDateProps(
          startOfWeek(new Date(), { weekStartsOn: 1 }),
          endOfWeek(new Date(), { weekStartsOn: 1 })
        ),
        nextWeek: this.convertToDateProps(
          startOfWeek(week, { weekStartsOn: 1 }),
          endOfWeek(week, { weekStartsOn: 1 })
        ),
        month: this.convertToDateProps(
          startOfMonth(new Date()),
          endOfMonth(new Date())
        ),
      };
    },
    range() {
      const index = document.querySelector(
        'meta[name="VUE_APP_ALGOLIASEARCH_INDICE"]'
      ).content;
      const state = this.instantSearchInstance.renderState[index];
      if (!state) return;

      const refinementList = state.refinementList;
      if (!refinementList) return;

      const minItems = refinementList[ALGOLIA_SEARCH_EVENT_DATE_START].items;
      const maxItems = refinementList[ALGOLIA_SEARCH_EVENT_DATE_END].items;
      return {
        min: minItems.length
          ? minItems.reduce((a, c) => (a.value < c.value ? a : c)).value
          : null,
        max: maxItems.length
          ? maxItems.reduce((a, c) => (a.value > c.value ? a : c)).value
          : null,
      };
    },
  },
  methods: {
    setFilters(start, end, type) {
      const index = this.optionList.findIndex((x) => x.type === type);
      if (this.optionList[index].active) {
        this.$emit("filterDate");
        this.optionList.map((x) => (x.active = false));
      } else {
        this.$emit("filterDate", { start, end });
        this.optionList.map((x) => (x.active = false));
        this.optionList[index].active = true;
      }
    },
    convertToDateProps(start, end) {
      const startS = format(start, "t");
      const endS = format(end, "t");

      return {
        start,
        end,
        startS,
        endS,
        available: this.isAvailable(startS, endS),
      };
    },
    isAvailable(start, end) {
      // @TODO: Fix this function and enable it (range isn't correctly calculated)
      /* eslint-disable no-unreachable */
      //return true;

      if (!this.range) return false;
      // Check if this [day/tomorrow/week/next/month] is available
      // Latest end of the event (range max) should be after the start of this [day]
      const checkStart = (this.range.max ? this.range.max : 0) > start;
      // Earliest start of event (range min) should be before the end of this [day]
      const checkEnd = (this.range.min ? this.range.min : 0) < end;
      return checkStart && checkEnd;
      /* eslint-enable no-unreachable */
    },
  },
};
</script>
