<template>
  <ul class="search-list" :class="{ 'product-list': category }">
    <component
      :is="componentItem(item)"
      v-for="item in items"
      :key="item.slug"
      :item="mapItem(item)"
    ></component>
    <li v-if="items.length === 0" class="updates__no-results">
      <slot></slot>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SearchList",
  props: {
    placeholderImage: {
      type: String,
      required: true,
    },
    items: {
      required: true,
      type: Array,
    },
    labels: {
      required: true,
      type: Object,
    },
    category: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {},
  methods: {
    componentItem(item) {
      if (this.category) {
        return "product-tile";
      }
      return `search-item-${item.type}`;
    },
    mapItem(item) {
      // Using Craft ElementAPI
      if (!item.objectID) {
        return item;
      }

      // Using Algolia index
      return {
        ...item,
        images: item.media,
      };
    },
  },
};
</script>
