<template>
  <ais-instant-search
    :search-client="client"
    :index-name="indexName"
    :routing="routing"
    :search-function="searchFunction"
    :initial-ui-state="initialUiState"
  >
    <!-- eslint-disable-next-line -->
    <ais-configure v-if="filtersFormatted" :filters="filtersFormatted" :page="0" :hitsPerPage="20" :maxValuesPerFacet="20" :attributes="['*']" />

    <div
      class="algolia-search grid-container"
      :class="{ 'algolia-search--category': category }"
    >
      <div v-if="showSearch" class="algolia-search__search">
        <ais-search-box
          :class-names="{
            'ais-SearchBox': 'searchbox',
          }"
        >
          <debounced-search-box
            :search-index-suggestions="indexName"
            :delay="500"
            :search-base-url="searchBaseUrl"
            :placeholder="labels.search"
          />
        </ais-search-box>
      </div>

      <div class="algolia-search__sidebar">
        <button
          class="button--secondary button--filters"
          @click="switchFilters"
        >
          <i class="fal fa-sliders-h"></i>Filters
        </button>

        <div class="filters-header">
          <ais-current-refinements
            :transform-items="transformCurrentRefinements"
          />
        </div>

        <div
          class="filters-lists"
          :class="{ 'filters-lists--open': filtersOpen }"
        >
          <div class="filters-lists__header">
            <h4>Filters</h4>
            <button class="button--close" @click="switchFilters">
              <i class="fal fa-times"></i>
            </button>
          </div>

          <button
            class="button--primary button--show-results"
            @click="switchFilters"
          >
            <ais-stats>
              <p slot-scope="{ nbHits }">
                Toon {{ nbHits }}
                {{ nbHits > 1 ? labels.results : "resultaat" }}
              </p>
            </ais-stats>
          </button>

          <div
            v-for="(attribute, index) in attributes"
            :key="index"
            class="filters-list"
            :class="{ hide: attribute.type === 'range' }"
          >
            <h4 class="filters-title">{{ attribute.name }}</h4>
            <ais-refinement-list
              :attribute="attribute.metadata"
              :searchable="attribute.filter"
              :searchable-placeholder="
                `Zoeken in ${attribute.name.toLowerCase()}...`
              "
              :limit="5"
              show-more
            />
          </div>

          <div
            v-if="attributes && attributes.length && category && showDateFilter"
            class="filters-list"
          >
            <h4 class="filters-title">Datum</h4>
            <search-date-filter :attribute="{}" @filterDate="setDateFilters" />
          </div>
        </div>
      </div>

      <div class="algolia-search__main">
        <ais-stats>
          <p
            slot-scope="{ nbHits, query }"
            class="algolia-search__results"
            :class="{ bold: !nbHits }"
          >
            <b v-if="query">
              {{ nbHits }} {{ labels.resultsFor }} ‘{{ query }}’
            </b>
            <b v-else> {{ nbHits }} {{ labels.results }} </b>
          </p>
        </ais-stats>

        <ais-hits>
          <search-list
            slot-scope="{ items }"
            :items="items"
            :labels="labels"
            :placeholder-image="placeholderImage"
            :category="category"
          >
            {{ labels.noResultsSentence }}
          </search-list>
        </ais-hits>

        <ais-state-results>
          <template slot-scope="{ results: { nbPages } }">
            <ais-pagination
              v-if="nbPages > 1"
              class="pagination"
              :total-pages="5"
            />
            <!-- Component needs at least a div to work -->
            <div v-else></div>
          </template>
        </ais-state-results>
      </div>
    </div>
  </ais-instant-search>
</template>

<script>
import {
  ALGOLIA_SEARCH_EVENT_DATE_START,
  ALGOLIA_SEARCH_EVENT_DATE_END,
  ALGOLIA_SEARCH_DATE_START,
  ALGOLIA_SEARCH_DATE_END,
} from "../../../../services/search/constants";
import algoliasearch from "algoliasearch/lite";
import { history as historyRouter } from "instantsearch.js/es/lib/routers";
import { singleIndex as singleIndexMapping } from "instantsearch.js/es/lib/stateMappings";
import DebouncedSearchBox from "./DebouncedSearchBox.vue";

import {
  AisInstantSearch,
  AisSearchBox,
  AisHits,
  AisRefinementList,
  AisPagination,
  AisStats,
  AisStateResults,
  AisConfigure,
  AisCurrentRefinements,
} from "vue-instantsearch";

import { format, startOfToday, endOfToday } from "date-fns";

export default {
  name: "AlgoliaSearch",
  components: {
    DebouncedSearchBox,
    AisInstantSearch,
    AisSearchBox,
    AisHits,
    AisRefinementList,
    AisPagination,
    AisStats,
    AisStateResults,
    AisConfigure,
    AisCurrentRefinements,
  },
  props: {
    labels: {
      required: true,
      type: Object,
    },
    searchBaseUrl: {
      type: String,
      required: true,
    },
    placeholderImage: {
      type: String,
      required: false,
      default: "",
    },
    category: {
      type: String,
      required: false,
      default: null,
    },
    categoryFilters: {
      required: false,
      type: Object,
      default: () => {
        return {
          meta: [],
          filters: "",
        };
      },
    },
  },
  data() {
    return {
      client: algoliasearch(
        document.querySelector(
          'meta[name="VUE_APP_ALGOLIASEARCH_APPLICATION_ID"]'
        ).content,
        document.querySelector('meta[name="VUE_APP_ALGOLIASEARCH_API_KEY"]')
          .content
      ),
      routing: {
        router: historyRouter(),
        stateMapping: singleIndexMapping(
          document.querySelector('meta[name="VUE_APP_ALGOLIASEARCH_INDICE"]')
            .content
        ),
      },
      filtersOpen: false,
      filters: [],
      attributes: [],
      facetOrder: [],
      didSetFacets: false,
    };
  },
  computed: {
    initialUiState() {
      const state = {};
      state[this.indexName] = {
        page: 0,
      };
      return state;
    },
    indexName() {
      return document.querySelector('meta[name="VUE_APP_ALGOLIASEARCH_INDICE"]')
        .content;
    },
    showSearch() {
      if (this.category) return;
      return true;
    },
    filtersFormatted() {
      if (this.filters.length) {
        return this.filters.join(" AND ");
      }
      return null;
    },
    showDateFilter() {
      return this.categoryFilters.filters.includes("dates");
    },
  },
  created() {
    this.setPreFilters();
    this.setAttributes();
    this.getFacetOrder();
  },
  methods: {
    async searchFunction(helper) {
      // Only send request to algolia once the first query has defined the facets.
      // Placeholder to just always search for now. helper.state is now managed properly by the rest of the code.
      helper.search();
    },
    getFacetOrder() {
      this.client.initIndex(this.indexName);
      const originalSearch = this.client.search;
      this.client.search = async (request) => {
        return await originalSearch(request).then((response) => {
          if (this.didSetFacets === false) {
            this.didSetFacets = true;
            this.facetOrder =
              response.results[0].renderingContent.facetOrdering.facets.order;
            this.setAttributes();
          }
          return response;
        });
      };
    },
    setAttributes() {
      let filters = [];
      if (this.category) {
        // Add date filters hardcoded if it is required
        if (this.showDateFilter) {
          filters = [
            {
              name: "Start",
              metadata: ALGOLIA_SEARCH_EVENT_DATE_START,
              filter: false,
              type: "range",
            },
            {
              name: "Einde",
              metadata: ALGOLIA_SEARCH_EVENT_DATE_END,
              filter: false,
              type: "range",
            },
          ];
        }

        const attributes = this.facetOrder.map(function(x) {
          const attribute = {
            filter: false,
            name: x.replace("metadata.", ""),
            type: "multi-select",
            metadata: x,
          };
          return attribute;
        });

        // Add filters from API and match with the order from Algolia
        if (this.categoryFilters.meta.length) {
          // Get enabled filters from API
          const enabledFilters = this.categoryFilters.meta.map(
            (x) => x.metadata
          );
          // Filter with the sorting order from Algolia
          const enabledFiltersSorted = attributes.filter((x) =>
            enabledFilters.includes(x.metadata)
          );
          filters = filters.concat(enabledFiltersSorted);
        }
      } else {
        // Regular search page
        filters = [{ name: "Type", metadata: "type", filter: false }];
      }
      // Only update attributes if they are different.
      if (JSON.stringify(filters) !== JSON.stringify(this.attributes)) {
        this.attributes = filters;
      }
    },
    setPreFilters() {
      // Pre-filter for market
      const host = window.location.hostname.split(".")[0];
      const allMarketId = {
        studenten: 3,
        educatie: 4,
      };
      this.filters = [
        `market.id:${allMarketId[host] ?? 1}`,
        `${ALGOLIA_SEARCH_DATE_START} <= ${format(endOfToday(), "t")}`,
        `status:published`,
      ];

      // Pre-filter for products when a category is defined
      if (this.category) {
        this.filters = [
          ...this.filters,
          `type:product`,
          `categories:${this.category}`,
          `${ALGOLIA_SEARCH_DATE_END} >= ${format(startOfToday(), "t")}`,
        ];
      }
    },
    setDateFilters(filters) {
      // Remove doubles
      this.filters = this.filters.filter((x) => !x.includes("event_"));

      if (filters) {
        // Add filters
        this.filters = [
          ...this.filters,
          `${ALGOLIA_SEARCH_EVENT_DATE_END} >= ${filters.start}`,
          `${ALGOLIA_SEARCH_EVENT_DATE_START} <= ${filters.end}`,
        ];
      }
    },
    transformCurrentRefinements(items) {
      if (!items || items.length < 1) {
        return [];
      }
      // Combine refiments so they come out as one list, instead of one list per filter type
      let refinementsCombined = [];
      items.forEach((x) => {
        refinementsCombined = refinementsCombined.concat(x.refinements);
      });
      items[0].refinements = refinementsCombined;
      return items[0];
    },
    switchFilters() {
      this.filtersOpen ^= true;
      if (this.filtersOpen) {
        return document.body.classList.add("scroll-lock");
      }
      return document.body.classList.remove("scroll-lock");
    },
  },
};
</script>
