export const ALGOLIA_SEARCH_EVENT_DATE_START = "event_starts_at_unix";
export const ALGOLIA_SEARCH_EVENT_DATE_END = "event_ends_at_unix";
export const ALGOLIA_SEARCH_DATE_START = "starts_at_unix";
export const ALGOLIA_SEARCH_DATE_END = "ends_at_unix";

export default {
  ALGOLIA_SEARCH_EVENT_DATE_START,
  ALGOLIA_SEARCH_EVENT_DATE_END,
  ALGOLIA_SEARCH_DATE_START,
  ALGOLIA_SEARCH_DATE_END,
};
